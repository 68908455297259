<template>
  <div class="bp-newsletter-form">
    <form
      class="bp-newsletter-form__content"
      v-if="!newsletterSuccess"
      v-on:submit.prevent="subscribeUser"
    >
      <ArrowInput
        v-model="newsletterEmail"
        @submit="subscribeUser"
        :hasError="newsletterError"
        inputId="newsletterInputForm"
        :placeholder="'newsletter_placeholder'"
        :isLoading="newsletterLoading"
      />
      <div
        class="bp-newsletter-form__error text--clr-red"
        v-if="newsletterError"
      >
        {{ UI_ELEMENT(errorType) }}
      </div>
    </form>
    <div v-else>
      <div class="bp-newsletter-form__success text-heading-4 text--clr-lime">
        {{ UI_ELEMENT("newsletter_success_signup") }}
      </div>
    </div>
  </div>
</template>

<script>
import { validateEmail } from "@/utils"
import BleachAPI from "@/integration/BleachAPI"
import ArrowInput from "@/components/form/ArrowInput"

export default {
  components: {
    ArrowInput
  },
  data() {
    return {
      newsletterEmail: "",
      newsletterError: false,
      newsletterSuccess: false,
      newsletterLoading: false,
      errorType: ""
    }
  },
  methods: {
    subscribeUser() {
      this.newsletterError = false
      this.errorType = ""

      if (validateEmail(this.newsletterEmail) && !this.newsletterLoading) {
        this.newsletterLoading = true
        BleachAPI.subscribeToNewsletter(this.newsletterEmail)
          .then(res => {
            this.newsletterLoading = false
            if (res.data.newsletterSignup.result) {
              this.newsletterSuccess = true
            } else {
              this.newsletterError = true
              this.errorType = "newsletter_error_backend"
            }
          })
          .catch((/* err */) => {
            this.newsletterError = true
            this.errorType = "newsletter_error_backend"
            this.newsletterLoading = false
            // console.log(err)
          })
      } else {
        this.newsletterError = true
        this.errorType = "newsletter_error_invalid"
      }
    }
  },

  watch: {
    newsletterEmail() {
      this.newsletterError = false
    }
  }
}
</script>

<style lang="scss">
div.bp-newsletter-form {
  height: 95px;
  position: relative;

  .bp-input__container-field {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid $bleach-white;
  }

  &__content--focused {
    .bp-newsletter-form__arrow {
      right: -10px;
    }
  }

  &__content--loading {
    .bp-newsletter-form__arrow {
      right: -10px;
      path {
        animation: arrow-right-dissapear 1.25s infinite ease-out;

        @media (prefers-reduced-motion: reduce) {
          animation: none;
        }
      }
    }
  }

  &__success {
    @include media-breakpoint-between(md, lg) {
      text-align: center;
    }
  }

  &__font {
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}
</style>
